import request from '@/../utils/request'
const api_name = '/iotechserver/PasswordHistoryController'

export default {

    updatePassword(passwordHistoryVo){
        return request({
            url: `${api_name}/updatePassword`,
            header:"application/json;charset=UTF-8",
            method: 'post',
            data: passwordHistoryVo,
        })
    },
   

}