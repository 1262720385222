import request from '@/../utils/request'
const api_name = '/iotechserver/YsAccountManagementController'
export default {
    //分页查询萤石云账号信息
    pageYsAccounts(current, limit) {
        return request({
            url: `${api_name}/pageYsAccounts/${current}/${limit}`,
            method: 'get',
        })
    },

    //添加/修改萤石云账号信息
    updateYSAccount(YSAccountInfo) {
        return request({
            url: `${api_name}/updateYsAccount`,
            method: 'post',
            data:{
                id:YSAccountInfo.id,
                accountName:YSAccountInfo.accountName,
                appkey:YSAccountInfo.appkey,
                secret:YSAccountInfo.secret
            }
        })
    },

    //删除萤石云账号
    removeYSAccount(YSAccountInfo) {
        return request({
            url: `${api_name}/deleteYsAccount`,
            method: 'post',
            data:{
                id:YSAccountInfo.id,
            }
        })
    },
}