import request from '@/../utils/request'
const api_name = '/iotechserver/AppUserInfoAppController'

export default {
    // 查询
    getUserInfoPage(userInfoApp) {
        return request({
            url: `${api_name}/getUserInfoPage`,
            method: 'post',
            data: userInfoApp
        })
    },

    // 添加用户
    addUser(userInfoApp) {
        return request({
            url: `${api_name}/addUser`,
            method: 'post',
            data: userInfoApp
        })
    },
    isPassword(userInfoApp){
        return request({
            url: `${api_name}/isPassword`,
            method: 'post',
            data: userInfoApp
        })
    },
    changeUserMess(userInfoApp){
        return request({
            url: `${api_name}/changeUserMess`,
            method: 'post',
            data: userInfoApp
        })
    },
    delUserInfo(userInfoApp){
        return request({
            url: `${api_name}/delUserInfo`,
            method: 'post',
            data: userInfoApp
        })
    },
    goVideo(userInfoApp){
        return request({
            url:`${api_name}/goVideo`,
            method:'post',
            data: userInfoApp
        })
    }
}