//验证纯数字
let regNumber = /^[0-9]*$/;
//验证身份证
let IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
let IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;

//验证邮箱
let regEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;//验证邮箱的正则表达式


export default{
    //验证用户名
    checkUserName: (rule, value, callback) => {
        value = value.replace(/\s*/g, "");//去除空格
        if (!value) {
            return callback(new callback('请输入用户名'))
        }
        if (value.length < 6 || value.length > 10) {
            callback(new Error("昵称的长度范围是[6-10]"))
        } else if (regNumber.test(value)) {
            callback(new Error("输入的内容不能是纯数字!!!"))
        } else {
            callback()
        }
    },

    //验证身份证
    checkIdCard : (rule, value, callback) => {
        value = value.replace(/\s*/g, "");//去除空格
        if (!value) {
            return callback(new callback('请输入身份证'))
        }

        // 校验身份证：
        if (IDRe18.test(value) || IDre15.test(value)) {
            callback()
        } else {
            callback(new Error("身份证不合法!!!"))
        }

    },

    // 验证手机号
    checkPhone : (rule, value, callback) => {
        value = value.replace(/\s*/g, "");//去除空格
        if (value === '') {
            callback(new Error('请输入电话号码'))
          }
          if (value.length !== 11) {
            callback(new Error('请输入正确电话号码'))
          }
          // 验证电话号码手机号码，包含至今所有号段? ?
          var ab = /^[1][3,4,5,7,8][0-9]{9}$/
          if (ab.test(value) === false) {
            callback(new Error('请输入正确电话号码'))
          }
          callback()
    },
    // 验证密码
    checkPwd :(rule, value, callback) => {
        value = value.replace(/\s*/g, "");//去除空格
       // let reg = /^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~@#$%*-+=:,\?[]{}]).{6,16}$/
        if (value.length < 6) {
          return callback(new Error('密码不能少于6位!'));
        } else if (value.length > 16) {
          return callback(new Error('密码最长不能超过16位!'));
        }
        //  else if (!reg.test(value)) {
        //   return callback(new Error('密码输入有误，请检查格式是否正确!'));
        // } 
        else {
          callback()
        }
    },
    
   /**
    * 只能输入大于0的正整数（不能以0开头）
    */
   integerFn(value) {
    let reg = /[1-9]{1}[0-9]*$/;
    let strArray = value.split("");
    let newStrs = "";
    for (let i = 0; i < strArray.length; i++) {
      if (reg.test(strArray[i])) {
        newStrs += strArray[i];
      } else if (i > 0 && strArray[i] === "0") {
        newStrs += strArray[i];
      }
    }
    if (newStrs - 0 > 0) {
      return newStrs - 0;
    } else {
      return "";
    }
  },
}


