<template>
  <div id="app" class="url">
    <!-- <el-row>
      <el-col :span="24" style="margin-bottom: 10px">
        <el-page-header @back="goBack" content="账号管理"> </el-page-header>
      </el-col>
    </el-row> -->
    <el-button type="success" style="margin:10px 0" class="el-icon-plus" @click="addAccount"> 添加账号
    </el-button>

    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="平台账号管理" name="first">
        <el-dialog title="添加账号" :visible.sync="dialogVisible" width="40%" style="background:rgba(0,0,0,0.5)">
          <el-form :model="addruleForm" status-icon :rules="rules" ref="addruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="账号" prop="username">
              <el-input v-model="addruleForm.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input show-password type="password" v-model="addruleForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkpassword">
              <el-input show-password type="password" v-model="addruleForm.checkpassword" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="type">
              <el-radio-group v-model="addruleForm.type" size="mini">
                <el-radio :label="0">普通账户</el-radio>
                <el-radio :label="1">非宝洁账户</el-radio>
                <el-radio :label="2">管理</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item style="margin:20px 0;">
              <el-button style="margin:10px;width:20%" plain type="primary" @click="resets(addruleForm)">重置</el-button>
              <el-button class="btn" type="primary" @click="addUser('ruleForm')">提交</el-button>
              <el-button class="btn" @click="dialogVisible = false">取消</el-button>
            </el-form-item>
          </el-form>
          <!-- <div class="box">
            <el-tag class="item">账 &nbsp; 号</el-tag>
            <el-input class="item-inp" v-model="username" placeholder="请输入用户名"></el-input>
          </div>

          <div class="box">
            <el-tag class="item">密 &nbsp; 码</el-tag>
            <el-input minlength="8" class="item-inp" v-model="password" placeholder="请输入密码"></el-input>
          </div>
          <br>
          <el-radio-group v-model="type" size="mini">
            <el-radio :label="0">普通账户</el-radio>
            <el-radio :label="1">非宝洁账户</el-radio>
            <el-radio :label="2">管理</el-radio></el-radio>
          </el-radio-group>

          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary"  @click="addUser">确 定</el-button>
          </span> -->

        </el-dialog>

          <el-table :data="userInfo" style="width: 100%" height="630px">
            <el-table-column prop="date" label="序号" type="index" width="120" align="center">
            </el-table-column>
            <el-table-column prop="username" label="账号" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="500" align="center">
              <template slot-scope="scope">
                <div>
                  <!-- 3D权限按钮 -->
                  <el-button type="primary" size="mini" icon="el-icon-tickets" @click="threejs(scope.row)">3D
                  </el-button>
                  <el-button type="primary" size="mini" icon="el-icon-tickets" @click="editIndustry(scope.row)">行业
                  </el-button>
                  <el-button type="primary" size="mini" icon="el-icon-edit-outline" @click="editInfo(scope.row)">编辑
                  </el-button>
                  <el-button type="warning" size="mini" icon="el-icon-edit" @click="reset(scope.row)">重置</el-button>
                  <el-button type="danger" size="mini" icon="el-icon-delete" @click="removeUser(scope.row)">删除
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- 是否开启3D的弹框 -->
          <el-dialog  @close="clearDialogBox" :title='threeDialogTitle' :visible.sync="showThreeDialog" height="20%" width="30%" :close-on-click-modal="false">
            <el-form :rules="threeDialogRules" :model="threejsData"  ref="threejsRef">
              <el-form-item prop="ifOpen" label="是否开启">
                <el-switch v-model="threejsData.ifOpen"></el-switch>
              </el-form-item>
              <el-form-item prop="threejsPath" v-show="threejsData.ifOpen">
                <el-input v-model="threejsData.threejsPath" placeholder="请输入文件名称"></el-input>
              </el-form-item>
            </el-form> 
            <!-- 表尾区域 -->
            <div slot="footer">
              <el-button @click="showThreeDialog = false">取 消</el-button>
              <el-button type="primary" @click="addThreejs">确 定</el-button>
            </div>
          </el-dialog>

          <el-dialog title="添加行业" :visible.sync="industryDialog" height="20%" width="30%" :close-on-click-modal="false" style="background:rgba(0,0,0,0.5)">
            <el-form>
              <el-select v-model="bindIndustrys" multiple placeholder="请选择" style="width: 100%;">
                <el-option v-for="item in industryList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <el-switch class="flag" v-model="isIndustry" active-color="#13ce66" inactive-color="#aaa" active-text="是否启用" style="text-align: right; height: 40px;">
              </el-switch>
            </el-form>
            <div slot="footer">
              <el-button @click="industryDialog = false">取 消</el-button>
              <el-button type="primary" @click="addIndustry">确 定</el-button>
            </div>
          </el-dialog>

          <el-dialog title="编辑信息" :visible.sync="editDialog" width="40%" style="background:rgba(0,0,0,0.4)">
            <div class="dialogbox">
              <div style="display:flex;width:100%;margin-bottom:20px">

                <div style="width:50%;">
                  <div class="title-text">平台名称：</div>
                  <el-input v-model="platformName" placeholder="请输入平台名称（字数控制在10个以内）" @blur="blurInfo('left')"></el-input>
                </div>
                <div style="width:50%;margin-left:10px">
                  <div class="title-text">主页左上角标语：</div>
                  <el-input v-model="platformSlogan" placeholder="请输入主页左上角标语：（字数控制在15个以内）" @blur="blurInfo('right')"></el-input>
                </div>
              </div>
              <div class="bginfo">
                <div class="title-text title-watch">
                  主页背景图：<span style="font-size:14px;color:#333;">推荐像素：1920 x 1080</span>
                  <el-switch class="watch" v-model="watch" active-color="#13ce66" inactive-color="#aaa" active-text="自定义" inactive-text="默认">
                  </el-switch>
                </div>
                <div class="img" v-if="watch">
                  <input type="file" @change="fileChange" ref="uploadimg" style="margin:10px 0;">
                  <!-- 图片 -->
                  <el-image :src="url" class="right" :preview-src-list="srcList">
                    <div slot="error" class="image-slot" style="width:200px">
                      <div style="text-align: center;margin-top:50px;color:#999">图片未上传</div>
                    </div>
                  </el-image>
                </div>
                <div v-else>
                  <!-- 默认图片 -->
                  <el-image :src="bgMap" class="right" :preview-src-list="bgMapList">
                    <div slot="error" class="image-slot" style="width:200px">
                      <div style="text-align: center;margin-top:50px;color:#999">图片未上传</div>
                    </div>
                  </el-image>
                </div>
              </div>
            </div>
            <div slot="footer">
              <el-button @click="editDialog = false">取 消</el-button>
              <el-button type="primary" @click="submitInfo">确 定</el-button>
            </div>
          </el-dialog>

        <!-- 分页 -->
        <el-row>
          <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="getUser"></el-pagination>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="萤石云账号管理" name="second">
        <el-button type="success" class="el-icon-plus" @click="showDialogYS({})"> 添加萤石云账号</el-button>
        <el-table :data="YSAccountList" height="600">
          <el-table-column prop="date" label="序号" type="index" width="120" align="center">
          </el-table-column>
          <el-table-column prop="accountName" label="萤石云账号名称" align="center">
          </el-table-column>
          <el-table-column prop="appkey" label="appKey" align="center">
          </el-table-column>
          <el-table-column type="password" prop="secret" label="Secret" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" icon="el-icon-edit" @click="showDialogYS(scope.row)">修改</el-button>
              <el-button type="danger" size="mini" icon="el-icon-delete" @click="removeYSAccount(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-dialog title="萤石云账号" :visible.sync="YSdialog" width="30%" @close="clearYSRule" style="background:rgba(0,0,0,0.5)">
          <el-form :model="YSAccountInfo" status-icon :rules="YSrules" ref="YSAccountInfo" label-width="100px" class="demo-ruleForm">
            <el-form-item label="账号名称" prop="accountName">
              <el-input v-model="YSAccountInfo.accountName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="appkey" prop="appkey">
              <el-input type="text" v-model="YSAccountInfo.appkey" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="secret" prop="secret">
              <el-input show-password type="password" v-model="YSAccountInfo.secret" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="updateYSAccount">提交</el-button>
              <el-button @click="cancelYSdialog()">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>

        <el-row>
          <el-pagination :current-page.sync="currentYS" :page-size="limitYS" :total="totalYS" layout="total, prev, pager, next, jumper" @current-change="pageYsAccounts"></el-pagination>
        </el-row>
      </el-tab-pane>

      <el-tab-pane v-if="false" label="智慧安全账号管理" name="third">
        <el-button type="success" class="el-icon-plus" @click="showDialogAPP"> 添加APP账号</el-button>

        <template>
          <el-table :data="userInfoAppList" height="700">
            <el-table-column prop="date" label="序号" type="index" width="120" align="center">
            </el-table-column>
            <el-table-column prop="powerSize" label="账号权限" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.power == 0 ? "提交人员" : "审批权限" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="所属用户" align="center">
            </el-table-column>
            <el-table-column prop="userName" label="账户名" align="center">
            </el-table-column>
            <el-table-column prop="phone" label="电话号" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.phone.split("_")[0] }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <div slot-scope="scope">
                <el-button type="primary" size="mini" icon="el-icon-edit" @click="showDialogAPP(scope.row)">修改
                </el-button>
                <el-button type="danger" size="mini" icon="el-icon-delete" @click="delAppUser(scope.row)">删除</el-button>
              </div>
            </el-table-column>
          </el-table>
        </template>

        <el-dialog :title="appdialogTitle" :visible.sync="Appdialog" width="30%" @close="cancelAppdialog">
          <el-form :model="userInfoApp" status-icon :rules="appRules" ref="userInfoApp" label-width="100px" class="demo-ruleForm">
            <el-form-item label="用户名" prop="userName">
              <el-input :disabled="changePasswordApp" v-model="userInfoApp.userName" autocomplete="off"></el-input>
            </el-form-item>

            <div v-if="changePasswordApp">
              <el-form-item label="旧密码" prop="oldPassword">
                <el-input type="text" @blur="oldPassword()" v-model="userInfoApp.oldPassword" autocomplete="off">
                </el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="password">
                <el-input type="text" v-model="userInfoApp.password" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="确认新密码" prop="confPassword">
                <el-input type="text" v-model="userInfoApp.confPassword" autocomplete="off"></el-input>
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item label="密码" prop="password">
                <el-input type="text" v-model="userInfoApp.password" autocomplete="off"></el-input>
              </el-form-item>
            </div>

            <el-form-item label="姓名" prop="name">
              <el-input type="text" v-model="userInfoApp.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input type="text" v-model="userInfoApp.phone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="身份证" prop="card">
              <el-input type="text" v-model="userInfoApp.card" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="power">
              <el-radio-group v-model="userInfoApp.power" size="mini">
                <el-radio :label="'0'">提交人员</el-radio>
                <el-radio :label="'1'">审批人员</el-radio>
                <el-radio :label="'2'">管理人员</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitApp">提交</el-button>
              <el-button @click="cancelAppdialog()">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>

        <!-- <el-row>
          <el-pagination :current-page.sync="appPage.current" :page-size="appPage.limit" :total="appPage.total"
            layout="total, prev, pager, next, jumper" @current-change="getUserInfoPage"></el-pagination>
        </el-row> -->
      </el-tab-pane>
    </el-tabs>

  </div>
</template>
<script>
import Industry from "../../api/manageApi/Industry";
import AccountManage from '@/api/manageApi/AccountManage'
import user from "@/api/manageApi/User";
import YSAccountManagement from "@/api/manageApi/YSAccountManagement";
import PasswordHistory from "@/api/manageApi/PasswordHistory";
import { encrypt } from " ../../../utils/jsencrypt";
import appUserInfoApp from "@/api/manageApi/AppUserInfoApp";
import rulesall from "../../../utils/rules";
import indestryAdministration from "@/api/manageApi/indestryAdministration";
import Limits from "@/api/manageApi/Limits.js";

export default {
  data() {
    let validatePassword = (rule, value, callback) => {
      var reg1 =
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*.])[\da-zA-Z~!@#$%^&*.]{8,16}$/; //密码必须是8位以上、必须含有字母、数字、特殊符号 Qa124*875
      var reg2 = /(123|234|345|456|567|678|789|012)/; //不能有3个连续数字
      if (!reg1.test(value)) {
        callback(new Error("密码必须是8-16位、必须含有字母、数字、特殊符号"));
      } else if (reg2.test(value)) {
        callback(new Error("不能有3个连续数字"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    //checkpassword
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.addruleForm.checkpassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const appPwdAgainCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.userInfoApp.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      // 控制3D权限
      threejsData:{
        ifOpen:false,
        threejsPath: '',
      },
      showThreeDialog: false,
      threeDialogTitle:'',
      threeUsername:'',
      threeDialogRules:{
        threejsPath:[
          { required: true, message: '请输入3D文件的名称', trigger: 'blur' },
        ]
      },

      industryList: [], //全部行业集合
      bindIndustrys: [], //账号下已绑定行业集合
      isIndustry: false,//是否启用行业管理
      dialogVisible: false,
      industryDialog: false,
      permissionTypeList: [],
      platformName: '',
      platformSlogan: '',
      pictureFlag: 0,

      editDialog: false,

      url: '',
      srcList: [],
      imgFiles: [],
      watch: false,


      bgMap: require('../../assets/images/bgMap.jpg'),
      bgMapList: [],
      users: '', //账号名称



      activeName: 'first',


      userInfoVo: {
        id: "",
        username: "",
        password: "",
        passwordNew: "",
        passwordOld: "",
      },
      ruleForm: {
        id: "",
        username: "",
        password: "", // 旧密码
        pass: "", // 新密码
        checkPass: "", // 确认新密码
      },
      rules: {

        pass: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            trigger: "blur",
            validator: validatePassword,
          },
        ],
        checkPass: [
          {
            validator: validatePass2,
            trigger: "blur",
          },
        ],
        // 添加账号 密码
        password: [
          {
            validator: validatePassword,
            trigger: "blur",
          },
        ],
        // 添加账号 确认密码
        checkpassword: [
          {
            validator: validatePass3,
            trigger: "blur",
          },
        ],

        username: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        },
        {
          min: 3,
          max: 11,
          message: "长度在 3 到 11 个字符",
          trigger: "blur",
        }],
      },
      dialogVisible1: false, // 修改密码
      dialogVisible: false, // 添加账号
      current: 1,
      limit: 10,
      total: 0,

      userInfo: [],

      addruleForm: {
        username: "",
        password: "",
        checkpassword: "",
        type: 0, //添加用户身份
      },
      //添加/修改萤石云账号信息时表单验证
      YSrules: {
        accountName: [
          { required: true, message: '请输入萤石云账号名称', trigger: 'blur' }
        ],
        appkey: [
          { required: true, message: '请输入萤石云appkey', trigger: 'blur' }
        ],
        secret: [
          { required: true, message: '请输入萤石云secret', trigger: 'blur' }
        ],
      },
      YSAccountList: [], //萤石云账号信息集合
      YSAccountInfo: {
        id: '',
        accountName: '',
        appkey: '',
        secret: ''
      },
      dialogAddYSAccount: false, // 添加萤石云账号信息
      dialogUpdateYSAccount: false, // 修改萤石云账号信息
      currentYS: 1,
      limitYS: 10,
      totalYS: 0,
      YSdialog: false,

      appdialogTitle: "添加账号",
      appPage: {
        current: 1,
        limit: 10,
        total: 0,
      },
      userInfoApp: {
        username: "",
        password: "",
        name: "",
        phone: "",
        card: "",
        power: "0",
        oldPassword: "",
        confPassword: "",
      },
      appRules: {
        userName: [
          { validator: rulesall.checkUserName, trigger: 'blur' }
        ],
        card: [
          { validator: rulesall.checkIdCard, trigger: 'blur' }
        ],
        phone: [
          { validator: rulesall.checkPhone, trigger: "blur" }
        ],
        password: [
          { validator: rulesall.checkPwd, trigger: 'blur' }
        ],
        oldPassword: [
          { validator: rulesall.checkPwd, trigger: 'blur' }
        ],
        confPassword: [
          { validator: appPwdAgainCheck, trigger: 'blur' }
        ],
      },
      userInfoAppList: [],
      Appdialog: false,
      changePasswordApp: false,

    };
  },

  created() {
    this.getIndustryList();
    this.bgMapList.push(this.bgMap)
    this.getUser();
    this.pageYsAccounts();
    // this.getUserInfoPage();
  },

  methods: {
    // 3D权限的添加和修改
    async addThreejs(){
      // 向后端发送的数据
      let obj = {}
      let open = (this.threejsData.ifOpen)?'1':'0'
      obj.username = this.threeUsername
      obj.path = this.threejsData.threejsPath
      obj.open = open
      console.log(obj);
      // 表单预验证
      let verify = false
      this.$refs.threejsRef.validate((valid) => {
        verify = valid
        });
      if(!verify){
        this.$message.error('请输入文件名')
        return
      }
      const res = await Limits.addlnformation(obj)
      console.log(res);
      if(res.data.code !== 20000){
        this.$message.error('修改失败')
      }
      this.$message.success('修改成功')
      this.showThreeDialog = false
      
      // 修改信息
    },
    // 点击获取数据
    async threejs(value){
      this.showThreeDialog = true
      this.threeDialogTitle = value.username
      this.threeUsername = value.username
      const {data:res} = await Limits.getInformation(this.threeUsername)
      console.log(res.data.info);   //username,path,open
      this.threejsData.threejsPath = res.data.info.path
      this.threejsData.ifOpen = (res.data.info.open == 1)?true:false
      console.log(value);
    },
    // 清空表单
    clearDialogBox(){
      this.$refs.threejsRef.resetFields()
    },
    resets(data) {
      console.log(data);
      data.username = ''
      data.password = ''
    },

    blurInfo(params) {
      if (params == 'left') {
        if (this.platformName.length > 10) {
          this.$message.warning('字数控制在10字以内！')
          this.platformName = ''
        }
      }
      if (params == 'right') {
        if (this.platformSlogan.length > 15) {
          this.$message.warning('字数控制在15字以内！')
          this.platformSlogan = ''
        }
      }
    },

    // 重置密码
    reset(row) {
      let _this = this
      this.$confirm("是否将密码重置为'12345678', 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        // _this.clear();
        this.userInfoVo = {
          id: "",
          username: "",
          password: "", // 密码为加密后乱码
        };
        this.userInfoVo.id = row.id;
        user.resetUserInfo(this.userInfoVo).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getUser();
        });
      });
    },

    editInfo(data) {
      this.editDialog = true;
      this.users = data.username
      console.log(this.users);
      // 查询已添加的所有权限类型
      AccountManage.getShowsByUserName(this.users).then(res => {
        console.log(res);
        this.platformName = res.data.data.data.platformName
        this.platformSlogan = res.data.data.data.platformSlogan
        this.watchwatch = res.data.data.data.showFlag
      })
      // Industry.saveAccountIndustry().then(res => {
      //   console.log(res);
      //   // this.permissionTypeList = res.data.data.list;
      //   // for (let i = 0; i < res.data.data.list.length; i++){
      //   //   this.getloginUserJurisIdByName[res.data.data.list[i].smallSystemName] =
      //   //     res.data.data.list[i].id;
      //   // }
      // });
    },

    updata(row) {
      // 当前选中数据赋值给Vo
      this.dialogVisible1 = true;

      this.userInfoVo = {
        id: row.id,
        username: row.username,
        password: row.password, // 密码为加密后乱码
      };
    },
    // 判断旧密码是否正确
    encrypt() {
      let aa = encrypt(this.ruleForm.password);
      this.userInfoVo.password = aa;
      user.isPassword(this.userInfoVo).then((res) => {
        console.log(res);
      });
    },
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    getIndustryList() {
      indestryAdministration.getIndustryList().then((res) => {
        console.log(res);
        this.industryList = res.data.data.industryInfoList;
      })


    },
    //查询此账号已绑定的行业
    editIndustry(row) {
      //将账号名称赋值给data中的users
      this.users = row.username;
      Industry.getIndustrysByName("userName", row.username).then((res) => {
        this.bindIndustrys = res.data.data.industrys;
        this.isIndustry = res.data.data.isIndustry == "1" ? true : false;
      })
      this.industryDialog = true;


    },
    //行业弹窗的确认按钮
    addIndustry() {
      if (this.isIndustry == true && this.bindIndustrys.length == 0) {
        this.$message.error("请选择行业")
      } else if (this.isIndustry == false && this.bindIndustrys.length != 0) {
        this.$message.error("请开启行业")
      } else {
        Industry.saveAccountIndustry(this.users, this.bindIndustrys, this.isIndustry ? "1" : "0").then((res) => {
          console.log(res);
          if (res.data.message == "保存成功") {
            this.$message({
              type: 'success',
              message: '添加成功'
            })
            this.industryDialog = false;
          } else {
            this.$message({
              type: 'error',
              message: '添加失败'
            })
          }
        })
      }
    },

    getTitleText() {
      AccountManage.getShowsByUserName(this.users).then(res => {
        console.log(res);
      })
    },


    submitInfo() {
      console.log(this.platformName);   // 平台名称

      console.log(this.platformSlogan); // 左上角标语

      if (this.watch) {
        this.pictureFlag = '1'
      } else {
        this.pictureFlag = '0'
      }
      console.log(this.pictureFlag);  // 设置图片展示方式  0 1

      console.log(this.imgFiles);

      let formData = new FormData();

      formData.append("platformName", this.platformName);
      formData.append("platformSlogan", this.platformSlogan);
      formData.append("showFlag", '1');
      formData.append("picture", this.imgFiles);
      formData.append("pictureFlag", this.pictureFlag);
      formData.append("userName", this.users);

      AccountManage.setShows(formData).then(res => {
        console.log(res);
        if (res.data.message == "添加成功") {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.editDialog = false
          // this.platformName = '';
          // this.platformSlogan = '';
          this.imgFiles = [];
          this.watch = false
          // this.getUser()
        }
      })
    },

    //圖片上传验证
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isSVG = file.type === "image/svg+xml";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!(isJPG || isPNG || isSVG || isJPEG)) {
        this.$message.error("上传图标只能是 JPG、PNG或SVG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图标大小不能超过 1MB!");
        return (isJPG || isPNG || isSVG) && isLt2M;
      }
    },

    // 选择背景图片
    fileChange(e) {
      this.imgFiles = e.target.files[0];   // 文件

      let fileReader = new FileReader();
      let type = "default";

      this.beforeAvatarUpload(this.imgFiles);

      //判断文件类型
      if (/image/.test(this.imgFiles.type)) {
        fileReader.readAsDataURL(this.imgFiles);
        type = "image";
      } else {
        fileReader.readAsText(this.imgFiles, "utf-8");
        type = "text";
      }

      this.srcList = [];
      fileReader.onload = () => {
        this.url = fileReader.result;
        this.srcList.push(this.url)
      };
      console.log(this.url);
      console.log(this.srcList);
    },

    getUser() {
      user.pageUserInfo(this.current, this.limit).then((res) => {
        // console.log(res);
        this.userInfo = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },

    removeUser(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        user.removeUserInfo(row.username).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getUser();
        });
      });
    },

    addAccount() {
      setTimeout(() => {
        this.addruleForm.username = ''
        this.addruleForm.password = ''
      }, 1000);
      this.addruleForm.checkpassword = ''
      this.dialogVisible = true;
    },
    addUser() {
      if (this.addruleForm.username == "" || this.addruleForm.password == "") {
        this.$message.error("用户名或密码不能为空！");
      } else {
        this.$refs.addruleForm.validate((valid) => {
          if (valid) {
            user
              .addUserInfo(this.addruleForm.username, this.addruleForm.password, this.addruleForm.type)
              .then((res) => {
                console.log(res);
                if (res.data.message == "添加成功") {
                  this.$message({
                    message: "添加成功！",
                    type: 'success'
                  });
                } else {
                  this.$message({
                    message: res.data.message,
                    type: 'error'
                  });
                }
                this.dialogVisible = false;
                this.addruleForm.username = "";
                this.addruleForm.password = "";

                this.getUser();
              });
          }
        })
      }
    },

    //分页查询萤石云账号信息
    pageYsAccounts() {
      YSAccountManagement.pageYsAccounts(this.currentYS, this.limitYS).then((res) => {
        this.YSAccountList = res.data.data.list;
        this.totalYS = res.data.data.total;
      })
    },

    //添加/修改弹窗
    showDialogYS(YSinfo) {
      this.YSAccountInfo = YSinfo;
      this.YSdialog = true;
    },

    //添加/修改萤石云账号信息
    updateYSAccount() {
      this.$refs.YSAccountInfo.validate((valid) => {
        if (valid) {
          YSAccountManagement.updateYSAccount(this.YSAccountInfo).then((res) => {
            if (res == undefined) { //账号名称已存在,保留弹框
            } else if (res.status == 200) {
              this.$message.success(res.data.message)
              this.YSdialog = false;
              this.YSAccountInfo = {}
            } else {
              this.$message.error(res.data.message)
              this.YSdialog = false;
              this.YSAccountInfo = {}
            }
            this.pageYsAccounts();
          })
        }
      })
    },

    //删除萤石云账号
    removeYSAccount(YSinfo) {
      this.$confirm(
        "确定删除吗?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        YSAccountManagement.removeYSAccount(YSinfo).then((res) => {
          if (res.data.message == "删除成功") {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
          this.pageYsAccounts();
        })
      })
    },
    //萤石云弹框取消按钮
    cancelYSdialog() {
      this.YSdialog = false;
    },

    //添加/修改萤石云账号信息弹窗的回调F
    clearYSRule() {
      this.pageYsAccounts();
      this.$refs.YSAccountInfo.clearValidate();
    },
    //分页查询app账号信息
    getUserInfoPage() {
      appUserInfoApp.getUserInfoPage(this.appPage).then((res) => {
        if (res.data.message == "暂无权限" || res.data.message == "暂无数据") {
          this.$message.error(res.data.message)
        } else {
          this.userInfoAppList = res.data.data.list
          this.appPage.total = res.data.data.total
        }
      })
    },
    // 添加账号 
    showDialogAPP(row) {
      this.Appdialog = true
      if (row.id == "" || row.id == null) {
        this.changePasswordApp = false
      } else {
        this.appdialogTitle = "修改信息"
        this.changePasswordApp = true

        //  新密码 旧密码
        row.password = ""
        row.phone = row.phone.substring(0, 11)
        this.userInfoApp = row;

      }
    },
    cancelAppdialog() {
      // this.userInfoApp = {}
      this.Appdialog = false
      this.getUserInfoPage()
    },
    // 提交表单 添加账号
    submitApp() {
      this.$refs.userInfoApp.validate((valid) => {
        if (valid) {
          if (this.changePasswordApp) {
            //  修改
            this.updateAppUser()
          } else {
            //  添加
            this.addAppUser()
          }
        }
      })
    },
    addAppUser() {
      //  提交接口
      this.userInfoApp.password = encrypt(this.userInfoApp.password)
      appUserInfoApp.addUser(this.userInfoApp).then((res) => {
        if (res.data.message == "用户名已存在") {
          this.$message.error(res.data.message);
        } else {
          // 添加成功 弹框 关闭 刷新页面
          this.$message.success(res.data.message);
          this.cancelAppdialog()  // 关闭弹框
          // this.getUserInfoPage()
        }
      })
    },
    updateAppUser() {
      //  更新接口
      this.userInfoApp.password = encrypt(this.userInfoApp.password)
      this.userInfoApp.phone + "_App"
      appUserInfoApp.changeUserMess(this.userInfoApp).then((res) => {
        if (res.data.message == "修改成功") {
          this.$message.success(res.data.message);
          this.cancelAppdialog()  // 关闭弹框
          // this.getUserInfoPage()
        }
      })
    },
    oldPassword() {
      var a = "";
      if (this.userInfoApp.oldPassword == null || this.userInfoApp.oldPassword == "") {
        a = this.userInfoApp.oldPassword
      } else {
        a = encrypt(this.userInfoApp.oldPassword)
      }
      let user = {
        id: this.userInfoApp.id,
        password: a
      }
      //接口
      appUserInfoApp.isPassword(user).then(res => {
        if (res.data.message === "旧密码错误") {
          //  禁用提交按钮
          this.$message.error(res.data.message);
        }
      })
    },
    delAppUser(row) {
      console.log(row.id);
      let user = {
        id: row.id
      }
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //接口
        appUserInfoApp.delUserInfo(user).then(res => {
          if (res.data.message === "删除成功") {
            //  禁用提交按钮
            this.$message.success(res.data.message);
            this.getUserInfoPage()
          }
        })
      }).catch(() => {
        this.$message({ type: 'info', message: '已取消删除' });
      });
    },

  },

  // 修改密码
  // submitForm(formName) {
  //   this.$refs[formName].validate((valid) => {
  //     if (valid) {
  //       //赋值密码
  //       this.userInfoVo.passwordOld = encrypt(this.ruleForm.password);
  //       this.userInfoVo.passwordNew = encrypt(this.ruleForm.pass);
  //       this.userInfoVo.username = this.ruleForm.username;

  //       PasswordHistory.updatePassword(this.userInfoVo).then((res) => {
  //         this.dialogVisible1 = false;
  //       });
  //     } else {
  //       return false;
  //     }
  //   });
  // },
  // resetForm(formName) {
  //   this.$refs[formName].resetFields();
  // },
  // clear() {
  //   this.userInfoVo = {
  //     id: "",
  //     username: "",
  //     password: "", // 密码为加密后乱码
  //   };
  // },


};
</script>

<style lang="scss" scoped>
#app {
  box-sizing: border-box;
  padding: 0 5px;
}
.box {
  display: flex;
}

.box:nth-child(1) {
  margin-bottom: 10px;
}

.box:nth-child(2) {
  margin-bottom: 10px;
}

.item {
  width: 70px;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
}

.item-inp {
  float: right;
}

.dialogbox {
  box-sizing: border-box;
  scrollbar-width: none;
  overflow: scroll;
  width: 100%;
  height: 300px;
  padding: 10px;
}

.title-text {
  font-size: 18px;
  font-weight: 600;
  color: rgba(15, 15, 4, 0.548);
  padding: 10px 0 5px 0;
}

.title-watch {
  width: 100%;

  .watch {
    float: right;
  }
}

// .right{
//     width: 100%;
// }
/deep/.el-image__preview {
  width: 25%;
}

::-webkit-scrollbar {
  display: none;
}

.flag {
  float: right;
}
.btn {
  width: 20%;
  float: right;
  margin: 10px;
}
</style>