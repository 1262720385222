import request from '@/../utils/request'
const api_name = '/ThreejsLimit'

export default{
    // 添加设备的3D权限
    addlnformation(obj){
        return request({
            url:`${api_name}/addInformation`,
            method:'post',
            data:obj
        })
    },
    // 查找设备的3D权限
    getInformation(username){
        return request({
            url:`${api_name}/getInformation/${username}`,
            method:'get',
        })
    },
}